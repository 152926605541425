export enum ETLCodes {
  Error,
  No,
  Yes,
  Confirm,
  UserName,
  Login,
  Password,
  ApplicationTitle,
  Logout,
  Menu,
  Home,
  Presence,
  Periode,
  Centre,
  Jour,
  Classe,
  Matiere,
  Heure,
  ShowAll,
  Date,
  FutureClasseHoraireCannotBeUpdated,
  ForgotEarlierPresenceMessage,
  FillingEarlierPresenceMessage,
  ChangeOtoXMessage,
  WhatDoYouWantToDo,
  MaintienX,
  LaisserO,
  UtiliserR,
  PlusTard,
  Corriger,
  PresenceUnfinishedMessage,
  LosingData,
  Quitter,
  Rester,
  PresenceComplete,
  PresenceIncomplete,
  TropTot,
  ErrorSave,
  TousPresents,
  Ok,
  ApprenantsPresents,
  NeutraliserLaClasse,
  AuthenticationInProgress,
  Unauthorized,
  UnauthorizedMessage,
  UnauthorizedMessage_1,
  UnauthorizedMessage_2,
  ADistance,
}
