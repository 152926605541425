import {
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  Classes,
  Collapse,
  Colors,
  Dialog,
  Divider,
  Icon,
  IconSize,
  Intent,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { useQuery } from "@tanstack/react-query";
import { uniqBy } from "lodash";
import {
  FGCustomInput,
  FGCustomPanel,
  FGCustomReadOnly,
  FGEmpty,
  FGSelectInput,
  FGTextInput,
  FieldGroup,
  Loading,
  showError,
  useFGFormik,
} from "nsitools-react";
import React, { useCallback } from "react";
import styled from "styled-components";

import { PresenceDto, SavePresenceDto } from "../../api";
import { ERoutes } from "../../AppRouter";
import { AlphabeticScrollbar } from "../../components/alphabetic-scrollbar/AlphabeticScrollbar";
import BasicFormGenerator from "../../components/BasicFormGenerator";
import Page from "../../components/Page";
import Stack from "../../components/Stack";
import { useClasseHoraireQuery } from "../../hooks/queries/useClasseHoraireQuery";
import { useCommonHooks } from "../../hooks/useCommonHooks";
import { ETLCodes } from "../../locales";

export interface PresencePageProps {}
const ValuesToShow = ["@", "X", "O", "R", "N"];
const SuccessStatus = ["J", "DM", "DN", "DP", "-", "T", "S"];
const PresentStatus = ["R", "X"];

const StackWithPadding = styled(Stack)`
  padding-right: 25px;
`;

const RoContainer = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
`;

export const PresencePage: React.FC<PresencePageProps> = () => {
  const { t, router, apis, showDialog } = useCommonHooks();
  const classeHoraireId = React.useMemo(
    () => +router?.editRouteParams?.id,
    [router?.editRouteParams?.id]
  );

  const { getClasseHoraireById, heures, refetchClasseHoraires } =
    useClasseHoraireQuery();

  const classeHoraireData = React.useMemo(
    () => getClasseHoraireById(classeHoraireId),
    [classeHoraireId, getClasseHoraireById]
  );

  const { isLoading, data } = useQuery(
    ["presences", classeHoraireId],
    async () => {
      return apis.presence.getPresencesByClasseHoraire(classeHoraireId);
    }
  );

  const scrollToRef = React.useRef<HTMLDivElement>();
  const onRefresh = React.useCallback(() => {
    scrollToRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  const [showAllStatus, setShowAllStatus] = React.useState(false);
  const filteredData = React.useMemo(
    () => data?.presences?.filter((d) => ValuesToShow.includes(d.statut)),
    [data]
  );

  const getIndexLabel = React.useCallback(
    (value: PresenceDto) => {
      return showAllStatus
        ? `${
            data.presences?.findIndex(
              (d) => d.idapprenant === value.idapprenant
            ) + 1
          } / ${data?.presences?.length}`
        : `${
            filteredData.findIndex((d) => d.idapprenant === value.idapprenant) +
            1
          } / ${filteredData?.length}`;
    },
    [data, filteredData, showAllStatus]
  );

  const [collapsed, setCollapse] = React.useState(false);
  const toggleCollapse = React.useCallback(() => {
    setCollapse((s) => !s);
  }, []);

  const [saving, setSaving] = React.useState(false);

  const scrollToIndex = React.useCallback((index: number) => {
    document
      .querySelector(`[data-index="${index}"]`)
      .scrollIntoView({ block: "center" });
  }, []);

  const submitFn = React.useCallback(
    async (d: SavePresenceDto) => {
      try {
        setSaving(true);
        var savedId = await apis.presence.savePresences(d);
        await refetchClasseHoraires();
        setSaving(false);
        router.goToEditPage(ERoutes.periode, savedId);
      } catch (error) {
        showError(t(ETLCodes.ErrorSave));
        setSaving(false);
      }
    },
    [apis.presence, refetchClasseHoraires, router, t]
  );

  const onSubmit = React.useCallback(
    async (d: SavePresenceDto) => {
      if (
        d.presences.filter((p) => p.statut === "@").length > 0 ||
        d.presenceFormateurs.filter((p) => p.statut === "@").length > 0
      ) {
        showDialog({
          message: (
            <div>
              <Icon
                icon={IconNames.WarningSign}
                size={IconSize.LARGE}
                style={{ display: "flex", justifyContent: "center" }}
              />
              <p />
              {t(ETLCodes.PresenceUnfinishedMessage)}
            </div>
          ),
          onConfirmed: () => {
            const firstIndexUnfinished =
              d.presenceFormateurs.findIndex((p) => p.statut === "@") > 0
                ? d.presenceFormateurs.findIndex((p) => p.statut === "@")
                : d.presences.findIndex((p) => p.statut === "@") +
                  d.presenceFormateurs.length;

            scrollToIndex(firstIndexUnfinished);
          },
          buttons: [
            {
              intent: "primary",
              icon: "tick-circle",
              text: t(ETLCodes.Ok),
              resultType: "yes",
            },
          ],
        });
      } else {
        submitFn(d);
      }
    },
    [scrollToIndex, showDialog, submitFn, t]
  );

  const formik = useFGFormik({
    initialValues: data,
    onSubmit: (d) => {
      onSubmit(d);
    },
  });

  const onNext = React.useCallback(() => {
    formik?.submitForm();
  }, [formik]);

  const [dialogData, setDialogData] = React.useState<{
    shown: boolean;
    index: number;
  }>({
    shown: false,
    index: undefined,
  });

  const onGoBack = React.useCallback(() => {
    if (formik?.dirty) {
      showDialog({
        message: (
          <div>
            <Icon
              icon={IconNames.WarningSign}
              size={IconSize.LARGE}
              style={{ display: "flex", justifyContent: "center" }}
            />
            <p />
            {t(ETLCodes.LosingData)}
            <p />
            {t(ETLCodes.WhatDoYouWantToDo)}
          </div>
        ),
        noTitle: true,
        onDenied: () => router.goToPage(ERoutes.periode),
        buttons: [
          {
            intent: "none",
            text: t(ETLCodes.Quitter),
            icon: "time",
            resultType: "no",
          },
          {
            intent: "primary",
            icon: "eraser",
            text: t(ETLCodes.Rester),
            resultType: "yes",
          },
        ],
      });
    } else {
      router.goToPage(ERoutes.periode);
    }
  }, [formik?.dirty, router, showDialog, t]);

  const scrollToLetter = useCallback((letter: string) => {
    document
      .querySelector(`[data-letter="${letter}"]`)
      .scrollIntoView({ block: "center" });
  }, []);

  const onAllPresentClicked = React.useCallback(() => {
    const valuesToChange = [...formik?.values?.presences];

    valuesToChange.forEach((v) => {
      if (ValuesToShow.includes(v.statut)) {
        v.statut = "X";
      }
    });

    formik.setFieldValue("presences", valuesToChange);
  }, [formik]);

  const onAllNeutraliseClicked = React.useCallback(() => {
    const valuesToChange = [...formik?.values?.presences];

    valuesToChange.forEach((v) => {
      if (ValuesToShow.includes(v.statut)) {
        v.statut = "N";
      }
    });

    formik.setFieldValue("presences", valuesToChange);
  }, [formik]);

  const canUserEditFormateurPresence = React.useCallback(
    (idformateur?: number) => {
      if (!!data?.idFormateurUser) {
        return data?.idFormateurUser !== idformateur;
      } else {
        return true;
      }
    },
    [data?.idFormateurUser]
  );

  const canUserEdit = React.useCallback(() => {
    return !["O", "N"].includes(
      data?.presenceFormateurs?.find(
        (pf) => pf.idformateur === data?.idFormateurUser
      )?.statut
    );
  }, [data?.idFormateurUser, data?.presenceFormateurs]);

  const presencesText = React.useMemo(
    () =>
      (
        formik?.values?.presences.filter((d) =>
          PresentStatus.includes(d.statut)
        ).length ?? 0
      ).toString() +
      " / " +
      (
        formik?.values?.presences.filter((d) => ValuesToShow.includes(d.statut))
          .length ?? 0
      ).toString() +
      " (" +
      (formik?.values?.presences.length ?? 0) +
      ")",
    [formik?.values?.presences]
  );

  const [aDistance, setADistance] = React.useState(false);
  const [savingADistance, setSavingADistance] = React.useState(false);
  React.useEffect(() => {
    if (data) {
      setADistance(data.aDistance);
    }
  }, [data]);

  const saveADistance = React.useCallback(
    async (newValue: boolean) => {
      try {
        setSavingADistance(true);
        await apis.classeHoraire.saveADistance(classeHoraireId, newValue);
        setADistance(newValue);
      } catch (e) {
        showError(t(ETLCodes.ErrorSave));
      } finally {
        setSavingADistance(false);
      }
    },
    [apis.classeHoraire, classeHoraireId, t]
  );

  return (
    <>
      <AlphabeticScrollbar
        validLetters={
          data
            ? uniqBy(
                data.presences
                  .filter(
                    (p) => showAllStatus || ValuesToShow.includes(p.statut)
                  )
                  .map((p) => p.apprenant[0].toUpperCase()),
                (value) => value
              )
            : []
        }
        onLetterSelected={scrollToLetter}
      />
      <Page
        title={t(ETLCodes.Presence)}
        onRefresh={onRefresh}
        refreshButtonProps={{ icon: "double-chevron-up" }}
        onGoBack={onGoBack}
        onNext={onNext}
        nextButtonProps={{
          icon: "floppy-disk",
          loading: saving,
          disabled: !canUserEdit(),
        }}
      >
        <div ref={scrollToRef} />
        <StackWithPadding gap={"1rem"}>
          <Card
            onClick={toggleCollapse}
            style={{ paddingBottom: collapsed ? "0" : "20px" }}
          >
            <div style={{ display: "flex", justifyContent: "end" }}>
              <Icon icon={collapsed ? "chevron-down" : "minus"} />
            </div>
            {classeHoraireData && (
              <>
                <Collapse isOpen={!collapsed} keepChildrenMounted={true}>
                  <BasicFormGenerator
                    initialValues={classeHoraireData}
                    onSubmit={() => {}}
                  >
                    <FieldGroup>
                      <FGTextInput
                        label={t(ETLCodes.Classe)}
                        name="codeClasse"
                        helperText={classeHoraireData?.nomClasse}
                        readonly
                      />
                      <FieldGroup columns={2}>
                        <FGTextInput
                          label={t(ETLCodes.Matiere)}
                          name="codeClasseMatiere"
                          helperText={classeHoraireData?.nomClasseMatiere}
                          readonly
                        />
                        <FieldGroup>
                          <FGTextInput
                            label={t(ETLCodes.Date)}
                            name="jourLabel"
                            readonly
                          />
                          <FGSelectInput
                            noLabel
                            name="heureDebut"
                            items={heures}
                            valueField="heure"
                            displayField="label"
                            readonly
                          />
                        </FieldGroup>
                      </FieldGroup>
                      <FieldGroup>
                        <FGCustomReadOnly
                          label={t(ETLCodes.ApprenantsPresents)}
                        >
                          {() => <RoContainer>{presencesText}</RoContainer>}
                        </FGCustomReadOnly>
                      </FieldGroup>
                      <FieldGroup columns={2}>
                        <ButtonGroup>
                          <Button
                            text={t(ETLCodes.TousPresents)}
                            icon={IconNames.Tick}
                            intent={Intent.PRIMARY}
                            onClick={(
                              event: React.MouseEvent<HTMLElement, MouseEvent>
                            ) => {
                              event.stopPropagation();
                              onAllPresentClicked();
                            }}
                            disabled={!canUserEdit()}
                            style={{ marginRight: "1em" }}
                          />
                          <Button
                            text={t(ETLCodes.NeutraliserLaClasse)}
                            intent={Intent.WARNING}
                            onClick={(
                              event: React.MouseEvent<HTMLElement, MouseEvent>
                            ) => {
                              event.stopPropagation();
                              onAllNeutraliseClicked();
                            }}
                            disabled={!canUserEdit()}
                          />
                        </ButtonGroup>
                        <FGEmpty />
                      </FieldGroup>
                      <FieldGroup>
                        <Checkbox
                          style={{ marginTop: "1em" }}
                          label={t(ETLCodes.ADistance)}
                          checked={aDistance}
                          onChange={() => saveADistance(!aDistance)}
                          disabled={savingADistance}
                        />
                        <Checkbox
                          style={{ marginTop: "1em" }}
                          label={t(ETLCodes.ShowAll)}
                          checked={showAllStatus}
                          onChange={() => setShowAllStatus(!showAllStatus)}
                        />
                      </FieldGroup>
                    </FieldGroup>
                  </BasicFormGenerator>
                </Collapse>
                {collapsed ? (
                  <BasicFormGenerator
                    initialValues={classeHoraireData}
                    onSubmit={() => {}}
                  >
                    <FieldGroup columns={2}>
                      <FGTextInput noLabel name="codeClasseMatiere" readonly />
                      <FGSelectInput
                        noLabel
                        name="heureDebut"
                        items={heures}
                        valueField="heure"
                        displayField="label"
                        readonly
                      />
                      <FGCustomReadOnly label={t(ETLCodes.ApprenantsPresents)}>
                        {() => <RoContainer>{presencesText}</RoContainer>}
                      </FGCustomReadOnly>
                    </FieldGroup>
                  </BasicFormGenerator>
                ) : (
                  <></>
                )}
              </>
            )}
          </Card>
          <BasicFormGenerator formik={formik} inline>
            <FGCustomInput noLabel>
              {() => (
                <>
                  <Loading isLoading={isLoading}>
                    {formik?.values?.presenceFormateurs.map((_, index) => (
                      <Card
                        style={{
                          marginBottom: "0.5rem",
                          backgroundColor: "#d5e1f5",
                        }}
                        key={`Formateur_${index}`}
                        data-index={index}
                      >
                        <FieldGroup>
                          <FieldGroup columns={[9, 3]}>
                            <FGTextInput
                              name={`presenceFormateurs[${index}].formateur`}
                              noLabel
                              readonly
                            />
                          </FieldGroup>
                          <FGCustomPanel>
                            {(ctx) => (
                              <>
                                <ButtonGroup
                                  style={{
                                    display: "flex",
                                    flex: 1,
                                    justifyContent: "space-around",
                                  }}
                                >
                                  <Button
                                    key={`Formateur_${index}_X`}
                                    text={"X"}
                                    onClick={() => {
                                      ctx?.formik?.setFieldValue(
                                        `presenceFormateurs[${index}].statut`,
                                        "X"
                                      );
                                    }}
                                    intent={
                                      ctx?.formik?.values.presenceFormateurs[
                                        index
                                      ].statut === "X"
                                        ? "success"
                                        : "none"
                                    }
                                    style={{ zIndex: 0 }}
                                    disabled={
                                      !canUserEditFormateurPresence(
                                        ctx?.formik?.values.presenceFormateurs[
                                          index
                                        ].idformateur
                                      ) || !canUserEdit()
                                    }
                                  />
                                  <Button
                                    key={`Formateur_${index}_O`}
                                    text={"O"}
                                    onClick={() => {
                                      ctx?.formik?.setFieldValue(
                                        `presenceFormateurs[${index}].statut`,
                                        "O"
                                      );
                                    }}
                                    intent={
                                      ctx?.formik?.values.presenceFormateurs[
                                        index
                                      ].statut === "O"
                                        ? "danger"
                                        : "none"
                                    }
                                    style={{ zIndex: 0 }}
                                    disabled={
                                      !canUserEditFormateurPresence(
                                        ctx?.formik?.values.presenceFormateurs[
                                          index
                                        ].idformateur
                                      ) || !canUserEdit()
                                    }
                                  />
                                  <Button
                                    key={`Formateur_${index}_R`}
                                    text={"R"}
                                    onClick={() => {
                                      ctx?.formik?.setFieldValue(
                                        `presenceFormateurs[${index}].statut`,
                                        "R"
                                      );
                                    }}
                                    intent={
                                      ctx?.formik?.values.presenceFormateurs[
                                        index
                                      ].statut === "R"
                                        ? "warning"
                                        : "none"
                                    }
                                    style={{ zIndex: 0 }}
                                    disabled={
                                      !canUserEditFormateurPresence(
                                        ctx?.formik?.values.presenceFormateurs[
                                          index
                                        ].idformateur
                                      ) || !canUserEdit()
                                    }
                                  />
                                  <Divider />
                                  <Button
                                    key={`Formateur_${index}_N`}
                                    text={"N"}
                                    onClick={() => {
                                      ctx?.formik?.setFieldValue(
                                        `presenceFormateurs[${index}].statut`,
                                        "N"
                                      );
                                    }}
                                    intent={
                                      ctx?.formik?.values.presenceFormateurs[
                                        index
                                      ].statut === "N"
                                        ? "primary"
                                        : "none"
                                    }
                                    style={{ zIndex: 0 }}
                                    disabled={
                                      !canUserEditFormateurPresence(
                                        ctx?.formik?.values.presenceFormateurs[
                                          index
                                        ].idformateur
                                      ) || !canUserEdit()
                                    }
                                  />
                                </ButtonGroup>
                              </>
                            )}
                          </FGCustomPanel>
                        </FieldGroup>
                      </Card>
                    ))}
                    {formik?.values?.presences.map((d, index) =>
                      showAllStatus || ValuesToShow.includes(d.statut) ? (
                        <Card
                          style={{ marginBottom: "0.5rem" }}
                          key={`Apprenant_${index}`}
                          data-letter={d.apprenant[0].toUpperCase()}
                          data-index={index + data.presenceFormateurs.length}
                        >
                          <FieldGroup>
                            <FieldGroup columns={[9, 3]}>
                              <FGTextInput
                                name={`presences[${index}].apprenant`}
                                noLabel
                                readonly
                              />
                              <p style={{ marginTop: "0.6rem" }}>
                                {getIndexLabel(d)}
                              </p>
                            </FieldGroup>
                            <FGCustomPanel>
                              {(ctx) => (
                                <>
                                  {ValuesToShow.includes(
                                    ctx?.formik?.values.presences[index].statut
                                  ) ? (
                                    <ButtonGroup
                                      style={{
                                        display: "flex",
                                        flex: 1,
                                        justifyContent: "space-around",
                                      }}
                                    >
                                      <Button
                                        key={`Apprenant_${index}_X`}
                                        text={"X"}
                                        onClick={() => {
                                          if (
                                            ctx?.formik?.values?.presences[
                                              index
                                            ].statut === "O"
                                          ) {
                                            setDialogData({
                                              shown: true,
                                              index,
                                            });
                                          } else {
                                            ctx?.formik?.setFieldValue(
                                              `presences[${index}].statut`,
                                              "X"
                                            );
                                          }
                                        }}
                                        intent={
                                          ctx?.formik?.values.presences[index]
                                            .statut === "X"
                                            ? "success"
                                            : "none"
                                        }
                                        style={{ zIndex: 0 }}
                                      />
                                      <Button
                                        key={`Apprenant_${index}_O`}
                                        text={"O"}
                                        onClick={() => {
                                          ctx?.formik?.setFieldValue(
                                            `presences[${index}].statut`,
                                            "O"
                                          );
                                        }}
                                        intent={
                                          ctx?.formik?.values.presences[index]
                                            .statut === "O"
                                            ? "danger"
                                            : "none"
                                        }
                                        style={{ zIndex: 0 }}
                                        disabled={!canUserEdit()}
                                      />
                                      <Button
                                        key={`Apprenant_${index}_R`}
                                        text={"R"}
                                        onClick={() => {
                                          ctx?.formik?.setFieldValue(
                                            `presences[${index}].statut`,
                                            "R"
                                          );
                                        }}
                                        intent={
                                          ctx?.formik?.values.presences[index]
                                            .statut === "R"
                                            ? "warning"
                                            : "none"
                                        }
                                        style={{ zIndex: 0 }}
                                        disabled={!canUserEdit()}
                                      />
                                      <Divider />
                                      <Button
                                        key={`Apprenant_${index}_N`}
                                        text={"N"}
                                        onClick={() => {
                                          ctx?.formik?.setFieldValue(
                                            `presences[${index}].statut`,
                                            "N"
                                          );
                                        }}
                                        intent={
                                          ctx?.formik?.values.presences[index]
                                            .statut === "N"
                                            ? "primary"
                                            : "none"
                                        }
                                        style={{ zIndex: 0 }}
                                        disabled={!canUserEdit()}
                                      />
                                    </ButtonGroup>
                                  ) : (
                                    <ButtonGroup
                                      style={{
                                        display: "flex",
                                        flex: 1,
                                        justifyContent: "space-around",
                                      }}
                                    >
                                      <Button
                                        key={`Apprenant_${index}_${ctx?.formik?.values.presences[index].statut}`}
                                        text={
                                          ctx?.formik?.values.presences[index]
                                            .statut
                                        }
                                        disabled
                                        minimal
                                        intent={
                                          SuccessStatus.includes(
                                            ctx?.formik?.values.presences[index]
                                              .statut
                                          )
                                            ? "success"
                                            : "danger"
                                        }
                                        style={{ zIndex: 0 }}
                                      />
                                    </ButtonGroup>
                                  )}
                                </>
                              )}
                            </FGCustomPanel>
                          </FieldGroup>
                        </Card>
                      ) : (
                        <></>
                      )
                    )}
                  </Loading>
                </>
              )}
            </FGCustomInput>
            <FGCustomPanel>
              {(ctx) => (
                <Dialog
                  isOpen={dialogData.shown}
                  canOutsideClickClose={false}
                  onClose={() =>
                    setDialogData({ shown: false, index: undefined })
                  }
                  style={{
                    paddingBottom: 0,
                    width: 500,
                    backgroundColor: Colors.WHITE,
                  }}
                >
                  <div className={Classes.DIALOG_BODY}>
                    <Icon
                      icon={IconNames.WarningSign}
                      size={IconSize.LARGE}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    />
                    <p />
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        justifyContent: "space-around",
                        fontWeight: "bold",
                      }}
                    >
                      {
                        ctx?.formik?.values?.presences[dialogData.index]
                          ?.apprenant
                      }
                    </div>
                    <p />
                    {t(ETLCodes.ChangeOtoXMessage)}
                    <p />
                    {t(ETLCodes.WhatDoYouWantToDo)}
                    <p />
                    <ButtonGroup
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                        justifyContent: "space-around",
                      }}
                    >
                      <Button
                        key={`Apprenant_${dialogData.index}_X`}
                        text={t(ETLCodes.MaintienX)}
                        onClick={() => {
                          ctx?.formik?.setFieldValue(
                            `presences[${dialogData.index}].statut`,
                            "X"
                          );
                          setDialogData({ shown: false, index: undefined });
                        }}
                        intent={"success"}
                        style={{ marginBottom: "1em" }}
                      />
                      <Button
                        key={`Apprenant_${dialogData.index}_O`}
                        text={t(ETLCodes.LaisserO)}
                        onClick={() => {
                          ctx?.formik?.setFieldValue(
                            `presences[${dialogData.index}].statut`,
                            "O"
                          );
                          setDialogData({ shown: false, index: undefined });
                        }}
                        intent={"danger"}
                        style={{ marginBottom: "1em" }}
                      />
                      <Button
                        key={`Apprenant_${dialogData.index}_R`}
                        text={t(ETLCodes.UtiliserR)}
                        onClick={() => {
                          ctx?.formik?.setFieldValue(
                            `presences[${dialogData.index}].statut`,
                            "R"
                          );
                          setDialogData({ shown: false, index: undefined });
                        }}
                        intent={"warning"}
                        style={{ marginBottom: "1em" }}
                      />
                    </ButtonGroup>
                  </div>
                </Dialog>
              )}
            </FGCustomPanel>
          </BasicFormGenerator>
        </StackWithPadding>
      </Page>
    </>
  );
};

export default PresencePage;
